'use client';

import { SessionProvider } from 'next-auth/react';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import createEmotionCache from '@/utils/createEmotionCache';
import theme from '@/theme';

const clientSideEmotionCache = createEmotionCache();

export default function RootLayout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <body>
        <SessionProvider>
          <CacheProvider value={clientSideEmotionCache}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
              </ThemeProvider>
            </StyledEngineProvider>
          </CacheProvider>
        </SessionProvider>
      </body>
    </html>
  );
}
