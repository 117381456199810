'use client';

import { useSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import LoadingSpinner from '@/components/utils/LoadingSpinner';

export default function LoginPage() {
  const { status } = useSession(); // Get session status
  const router = useRouter();
  const [error, setError] = useState(''); // State for errors

  // Handle authenticated users
  useEffect(() => {
    if (status === 'authenticated') {
      router.push('/products'); // Redirect after rendering
    }
  }, [status, router]);

  // Show loading spinner while session is being checked
  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(''); // Clear previous errors
    const formData = new FormData(e.currentTarget);

    const result = await signIn('credentials', {
      username: formData.get('username'),
      password: formData.get('password'),
      redirect: false,
    });

    if (!result?.ok) {
      setError('Invalid username or password.');
    } else if (result?.url) {
      router.push(result.url); // Redirect after successful login
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
      <Typography variant="h4" gutterBottom>
        Admin Login
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <TextField
          name="username"
          label="Username"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </form>
    </Box>
  );
}
